import './email.scss'

export default function Email4() {
    return (
        <table className="email-container" id="email4" cellSpacing="0" cellPadding="0" bgcolor="#FFFFFF" border="0">
            <thead>
                <tr>
                    <td className="email-header mb-2" width="640">
                        <h3>Thank You</h3>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p>Hello [CUSTOMER NAME],</p>
                        <p>
                            I just wanted to thank you again for taking the time to talk with me about ACME Widgets and give me the chance to provide you with a personalized quote.
                        </p>
                        <p>
                            I understand that there are many reasons why choosing ACME Widgets may not be right at this time, but I just wanted to let you know that I'll keep your information and quote here on file in case your situation changes. I believe in our product, and I believe it can help your business. I would love to stay in touch, and as always, our support team and I are always here to answer any questions you have or help in any way we can.
                        </p>
                        <p>Thank you,</p>
                        <p>[SALES REP NAME]</p>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}