import './email.scss'
import Button from 'react-bootstrap/Button'

export default function Email2() {
    return (
        <table className="email-container" id="email2" cellSpacing="0" cellPadding="0" bgcolor="#FFFFFF" border="0">
            <thead>
                <tr>
                    <td className="email-header mb-2" bgcolor="#0006ff" width="640">
                        <h3>Thank You!</h3>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p style={{ marginTop: 12 + `px` }}>Hello [CUSTOMER NAME], it's [SALES REP NAME].</p>
                        <p>
                            Thank you for taking the time to learn more about ACME Widgets! I know your time is valuable and I want you to feel it was well spent.
                        </p>
                        <p>
                            As your ACME Widget Sales Rep, I would love to schedule a time to go over your personalized Widget quote. [PERSONALIZED MESSAGE&mdash;example: "We mentioned a few numbers when we met. After looking over the information you gave me, I made a few adjustments to the original quote and came up with a solution that I think will fit your needs better at about the same cost."]
                        </p>
                        <p>Click the button below to open my appointment calendar and schedule a time that works best for you!</p>
                    </td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                </tr>
                <tr className="cta-row">
                    <td>
                        <Button variant="primary">Schedule Follow Up</Button>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}