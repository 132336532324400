import './email.scss'
import Button from 'react-bootstrap/Button'

import customerService from '../images/emails/email6/woman_with_phone.png'
import deliveryTruck from '../images/emails/email6/delivery_truck.png'
import puppyWithToy from '../images/emails/email6/puppy_with_toy.png'
import daschundBox from '../images/emails/email6/daschund_shipping_box.png'

export default function Email7() {
    return (
        <table className="email-container" id="email7" cellSpacing="0" cellPadding="0" bgcolor="#FFFFFF" border="0">
            <thead>
                <tr>
                    <td className="email-header mb-2" width="640">
                        <h3>Good News!</h3>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p>Your order from Persistent Pups has been carefully packaged and entrusted to [SHIPPING PROVIDER]. They've told us they will get it to you by [SHIPPING DELIVERY EST. DATE].</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Order #: [CUSTOMER ORDER NUMBER]</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Questions about your order? Call us at 1-800-555-5555, or e-mail us at <a href="mailto:support@persitentpups.com">support@persitentpups.com</a></p>
                    </td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td style={{ textAlign: `center` }}><Button>Contact Customer Support</Button></td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>
                        <table>
                            <tr>
                                <td><img src={customerService} alt="Woman talking on smart phone" /></td>
                                <td><img src={deliveryTruck} alt="Delivery truck on city street" /></td>
                                <td><img src={puppyWithToy} alt="Tired pug puppy resting paw on toy" /></td>
                                <td><img src={daschundBox} alt="Daschund dog sitting in shipping box" /></td>
                            </tr>
                            <tr style={{ textAlign: `center` }}>
                                <td>24/7 Customer Support</td>
                                <td>Free Delivery on Qualifying Orders</td>
                                <td>Pet Satisfaction Guaranteed</td>
                                <td>Hassle-free Returns</td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} width="640"><hr /></td>
                </tr>
            </tbody>
            <tfoot>
                <tr className="photo-credits">
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan="640">
                                        <span>
                                            Note: This section is not part of the email, it is for proper attribution on my website only.
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="320">
                                        Photo Credits:
                                    </td>
                                    <td width="320">
                                        <ul>
                                            <ol>
                                                Photo by <a href="https://unsplash.com/@brucemars?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">bruce mars</a> on <a href="https://unsplash.com/photos/woman-in-white-shirt-using-smartphone-FWVMhUa_wbY?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                                            </ol>
                                            <ol>
                                                Photo by <a href="https://unsplash.com/@trebron?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Norbert Kundrak</a> on <a href="https://unsplash.com/photos/white-van-parked-beside-brown-concrete-building-during-daytime-shMymEhk86g?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>

                                            </ol>
                                            <ol>
                                                Photo by <a href="https://unsplash.com/@charlesdeluvio?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">charlesdeluvio</a> on <a href="https://unsplash.com/photos/black-fawn-pug-playing-with-plush-toy-zAVOYirmXBw?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>

                                            </ol>
                                            <ol>
                                                Photo by <a href="https://unsplash.com/@erdaest?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Erda Estremera</a> on <a href="https://unsplash.com/photos/black-and-brown-dachshund-standing-in-box-sxNt9g77PE0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                                            </ol>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}