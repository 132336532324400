import '../scss/EmailWrap.scss'

// HTML Email Example wrapper component
import Accordion from 'react-bootstrap/Accordion'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

export default function EmailWrap({ email, index }) {
    const months = ['Jan', 'Feb', 'Mar', 'April', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const d = new Date();
    const emailDate = (email.date) ? email.date : `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`

    return (
        <Accordion key={`email-${index}`} className="mb-3">
            <Accordion.Item eventKey={index}>
                <Accordion.Header>
                    <Col xs={1} md={1}>{email.type}</Col>
                    <Col xs={2} md={2}>{email.product}</Col>
                    <Col xs={4} md={4}>{email.desc}</Col>
                    <Col xs={4} md={4}>{email.subjectLine}</Col>
                </Accordion.Header>
                <Accordion.Body>
                    <Container className="email-wrap-container">
                        <Row className="email-header">
                            <Col xs={8}>
                                <strong>From:</strong> noreply@{email.companyDomain} &lt;<a href="https://google.com" target="_blank" rel="noreferrer" className="unsubscribe">Unsubscribe</a>&gt;
                            </Col>
                            <Col xs={4} className="text-end">{emailDate}</Col>
                            <Col xs={12}><strong>Subject:</strong> {email.subjectLine}</Col>
                        </Row>
                        <Row className="email-body">
                            {email.emailBody}
                        </Row>
                    </Container>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}
