import './email.scss'
import Button from 'react-bootstrap/Button'

import playingDog from '../images/emails/email5/playing_dog.png'

export default function Email5() {
    return (
        <table className="email-container" id="email5" cellSpacing="0" cellPadding="0" bgcolor="#FFFFFF" border="0">
            <thead>
                <tr>
                    <td className="email-header mb-2" width="640">
                        <h3>We're excited to meet your dog(s)!</h3>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p>Thank you for subscribing to all the latest news and information about Persistent Pups, your furry family members' favorite new toys!</p>
                    </td>
                </tr>
                <tr>
                    <td style={{ textAlign: `center` }}>
                        <Button variant="primary">SHOP NOW</Button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Persistent Pups</strong> was created because we were tired of our dogs destroying toys within hours of getting them. Throwing them out felt wasteful, and it was costly to replace them.</p>

                        <p>We set out to craft our own with more durable materials, and we - and our dogs - were in love with the results! They were such a hit that we decided to begin selling them at our local Farmer's Market, and from there Persistent Pups was born and has continued to grow.</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h3>Here's why we're certain you (and your dog(s)) will love our toys:</h3>
                        <ul>
                            <li><strong>Built for Durability</strong>: Tough enough to endure your pup's wild playtimes.</li>
                            <li><strong>Safe &amp; Non-Toxic</strong>: Made from high-quality, dog-safe materials for safe play.</li>
                            <li><strong>Budget-Friendly</strong>: Premium quality without the premium price.</li>
                            <li><strong>Interactive &amp; Fun</strong>: Dogs love the squeak or crinkle of many of our toys.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td><h3>Special Offer for You!</h3></td>
                </tr>
                <tr>
                    <td><p>For a limited time, enjoy 15% off your first order with code [COUPON CODE] at checkout.</p></td>
                </tr>
                <tr>
                    <td style={{ textAlign: `center` }}>
                        <Button>Find Your Dog's New Obsession</Button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p style={{ marginBottom: 24 + `px` }}>Give your dog the gift of endless play&mdash;and yourself the gift of peace of mind!</p>
                    </td>
                </tr>
                <tr>
                    <td className="bkgd-img">
                        <img src={playingDog} alt="Dog playing with toy" />
                        <p><strong>Happy Playing,</strong></p>
                        <p><strong>Your Friends at Persistent Pups</strong></p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>P.S. Don't just take our word for it—read our customer reviews to see why dog owners everywhere are choosing Persistent Pups toys!</p>
                    </td>
                </tr>
                <tr>
                    <td width="640" style={{ paddingBottom: 24 + `px`, textAlign: `center` }}>
                        <Button>Read Reviews</Button>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} width="640"><hr /></td>
                </tr>
            </tbody>
            <tfoot>
                <tr className="photo-credits">
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan="640">
                                        <span>
                                            Note: This section is not part of the email, it is for proper attribution on my website only.
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="320">
                                        Photo Credits:
                                    </td>
                                    <td width="320">
                                        <ul>
                                            <ol>Photo by <a href="https://unsplash.com/@justinveenema?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Justin Veenema</a> on <a href="https://unsplash.com/photos/white-and-black-american-pitbull-terrier-bit-a-yellow-pig-toy-lying-on-grass-outdoor-during-daytime-A-YUeXZ1rq8?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></ol>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}