import './email.scss'

export default function Email3() {
    return (
        <table className="email-container" id="email3" cellSpacing="0" cellPadding="0" bgcolor="#FFFFFF" border="0">
            <thead>
                <tr>
                    <td className="email-header mb-2" width="640">
                        <h3>Congratulations</h3>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p>Hello again [CUSTOMER NAME]! I just wanted to thank you for choosing ACME Widgets, and say congratulations on taking the first step towards a more productive facility.</p>
                    </td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>
                        <h3>We're Here For You</h3>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            Of course this isn't the end of our professional relationship. In addition to our customer-centric support team, I'm here to make sure any questions you have get answered. If either customer support or I can't answer the question for you, we'll introduce you to one of our engineers that can.
                        </p>
                        <p>
                            For quick answers to small questions such as installation, be sure to check out our <a href="domain.org/faqs.html">extensive FAQs</a>, maintained by our engineering team.
                        </p>
                        <p>
                            I'll also follow up with you [PERSONALIZED FROM SALES REP&mdash;example: "next Monday", default: "soon"] to make certain that you are still happy with your Widgets purchase and answer any questions you have.
                        </p>
                        <p>Thank you,</p>
                        <p>[SALES REP NAME]</p>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}