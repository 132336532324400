import './email.scss'
import Button from 'react-bootstrap/Button'

// Images
import banner from '../images/emails/email1/dan-cristian-padure-dsJtesSBCeY-unsplash_banner.png'
import block2 from '../images/emails/email1/block2.png'
import block3 from '../images/emails/email1/block3.png'

export default function Email1() {
    return (
        <table className="email-container" id="email1" cellSpacing="0" cellPadding="0" bgcolor="#FFFFFF" border="0">
            <thead>
                <tr>
                    <td colSpan={2} style={{ textAlign: `center` }}>
                        <h2>Welcome to Acme Widgets!</h2>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={2} className="email-header mb-2" width="640">
                        <img src={banner} alt="Widgets on a white background" />
                        <table>
                            <tbody>
                                <tr>
                                    <td width="200">
                                        <h3>Explore Widget Options</h3>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} width="640">
                        <p style={{ marginTop: 12 + `px` }}>ACME Widgets were designed with our customers in mind. A one-size-fits all solution often requires compromise or sacrifice. With Widgets’ various options for multiple needs and budgets in mind, you can be certain to get the Widget that works best for you.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} width="640">&nbsp;</td>
                </tr>
                <tr className="block2">
                    <td width="200" style={{ backgroundColor: `#0006ff`, marginRight: 12 + `px`, verticalAlign: `middle` }}>
                        <img src={block2} alt="Man working on machine parts" />
                    </td>
                    <td width="627" style={{ paddingLeft: 12 + `px` }}>
                        <h3>Widget Quality</h3>
                        <p>ACME takes pride in its Widget craftsmanship. Our award-winning engineers spec the highest grade
                            materials, transformed by our skilled machinists using six-sigma quality standards. Widgets that fit
                            perfectly cause less wear, extending the life of the Widget and your equipment. Fewer breakdowns mean
                            less downtime and lower expenses, improving your overall ROI.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} width="640" style={{ lineHeight: 48 + `px` }}>&nbsp;</td>
                </tr>
                <tr className="block3">
                    <td colSpan={2} width="640" height="100" style={{ backgroundColor: `#0006ff` }}>
                        <img src={block3} alt="Smiling man with headset in call center" />
                        <h3>Support is Priority</h3>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} width="640">
                        <p>Our support team at ACME is well-trained and eager to answer your questions. If a problem does arise,
                            they are empowered to assist you in resolving it swiftly. If you are still not completely happy and
                            satisfied, ACME support agents will arrange to have your Widgets picked up and issue a full refund. You
                            can purchase ACME Widgets knowing that we have your back, every step of the way.</p>
                    </td>
                </tr>
                <tr className="cta-row">
                    <td colSpan={2} width="640">
                        <table>
                            <tbody>
                                <tr>
                                    <td width="320">
                                        <Button variant="secondary">LEARN MORE</Button>
                                    </td>
                                    <td width="320">
                                        <Button variant="primary">FIND A SALES REP</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} width="640"><hr /></td>
                </tr>
            </tbody>
            <tfoot>
                <tr className="photo-credits">
                    <td colSpan={2} width="640">
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan="640">
                                        <span>
                                            Note: This section is not part of the email, it is for proper attribution on my website only.
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="320">
                                        Photo Credits:
                                    </td>
                                    <td width="320">
                                        <ul>
                                            <ol>Photo by <a href="https://unsplash.com/@dancristianpaduret?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Dan Cristian Pădureț</a> on <a href="https://unsplash.com/photos/silver-and-gold-round-coins-dsJtesSBCeY?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></ol>
                                            <ol>Photo by <a href="https://unsplash.com/@museumsvictoria?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Museums Victoria</a> on <a href="https://unsplash.com/photos/man-in-gray-jacket-holding-a-machine-VK8DW6ax8K8?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></ol>
                                            <ol>Photo by <a href="https://unsplash.com/@vagaro?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Vagaro</a> on <a href="https://unsplash.com/photos/a-man-wearing-a-headset-sitting-in-front-of-a-computer-5skDrjF5EMw?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a></ol>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}